var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "contenr" }, [
      _c("div", { staticClass: "tab_list" }, [
        _c("div", { staticClass: "tab_left" }, [
          _c(
            "div",
            {
              staticClass: "tab_li",
              class: { active: _vm.index == 0 },
              on: {
                click: function ($event) {
                  _vm.index = 0
                },
              },
            },
            [_vm._v("某大型股份制银行")]
          ),
          _c(
            "div",
            {
              staticClass: "tab_li",
              class: { active: _vm.index == 1 },
              on: {
                click: function ($event) {
                  _vm.index = 1
                },
              },
            },
            [_vm._v("某大型证劵A")]
          ),
          _c(
            "div",
            {
              staticClass: "tab_li",
              class: { active: _vm.index == 2 },
              on: {
                click: function ($event) {
                  _vm.index = 2
                },
              },
            },
            [_vm._v("某大型证劵B")]
          ),
          _c(
            "div",
            {
              staticClass: "tab_li",
              class: { active: _vm.index == 3 },
              on: {
                click: function ($event) {
                  _vm.index = 3
                },
              },
            },
            [_vm._v("某信托登记机构")]
          ),
        ]),
        _c("div", { staticClass: "tab_right" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.index == 0,
                  expression: "index == 0",
                },
              ],
            },
            [
              _c("div", { staticClass: "tabtitle" }, [
                _vm._v("成功案例-某大型股份制银行"),
              ]),
              _vm._m(1),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.index == 1,
                  expression: "index == 1",
                },
              ],
            },
            [
              _c("div", { staticClass: "tabtitle" }, [
                _vm._v("成功案例-某大型证劵A"),
              ]),
              _vm._m(2),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.index == 2,
                  expression: "index == 2",
                },
              ],
            },
            [
              _c("div", { staticClass: "tabtitle" }, [
                _vm._v("成功案例-某大型证劵B"),
              ]),
              _vm._m(3),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.index == 3,
                  expression: "index == 3",
                },
              ],
            },
            [
              _c("div", { staticClass: "tabtitle" }, [
                _vm._v("成功案例-某信托登记机构"),
              ]),
              _vm._m(4),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hander_fu" }, [
      _c("div", { staticClass: "header-left" }, [
        _c("p", [_vm._v("经典案例")]),
        _c("span", [_vm._v("推动行业客户数字化转型，提升企业核心业务生产率")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nrbox" }, [
      _c("div", { staticClass: "nrbox_left" }, [
        _c("div", { staticClass: "lefttop_box" }, [
          _c("div", { staticClass: "nr_title" }, [_vm._v("项目背景")]),
          _c("div", { staticClass: "nr_row" }, [
            _vm._v(" 某股份制银行拥有的Nginx节点和集群较多，部署 "),
            _c("br"),
            _vm._v(" 复杂。 "),
            _c("br"),
            _vm._v(" 无法满足银行快速上线的要求，导致银行业务系统呈现 "),
            _c("br"),
            _vm._v(" 出开放性、用户量不可预测性、应用新功能更新频繁的 "),
            _c("br"),
            _vm._v(" 场景；系统弹性扩展能力不足的情况。 "),
          ]),
        ]),
        _c("div", { staticClass: "rightbottom_box" }, [
          _c("div", { staticClass: "nr_title" }, [_vm._v("项目目标")]),
          _c("div", { staticClass: "nr_row" }, [
            _vm._v(" ● 提供Nginx自动扫描功能去识别类型并纳管 "),
            _c("br"),
            _vm._v(" ● 提供平台化管理，统一入口，统一纳管 "),
            _c("br"),
            _vm._v(" ● 提供版本仓库用于统一管理Nginx版本 "),
            _c("br"),
            _vm._v(" ● 提供定制版可视化大屏 "),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "nrbox_right" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/casePage/bz_bg1.png"),
            width: "100%",
            height: "auto",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nrbox" }, [
      _c("div", { staticClass: "nrbox_left" }, [
        _c("div", { staticClass: "lefttop_box" }, [
          _c("div", { staticClass: "nr_title" }, [_vm._v("项目背景")]),
          _c("div", { staticClass: "nr_row" }, [
            _vm._v(" 为了同时满足业务部门对内部系统的统一管理和维护， "),
            _c("br"),
            _vm._v(" 落地建设了运维管理平台等相关系统。随着”一网四 "),
            _c("br"),
            _vm._v(" 云”建设的逐步深入与IDC运维规模的扩张，管理效率 "),
            _c("br"),
            _vm._v(" 低下，需要提高稳定性。 "),
          ]),
        ]),
        _c("div", { staticClass: "rightbottom_box" }, [
          _c("div", { staticClass: "nr_title" }, [_vm._v("项目目标")]),
          _c("div", { staticClass: "nr_row" }, [
            _vm._v(
              " ● 提供将Nginx从虚机迁移至容器部署；使用容器版Nginx Plus实例，为传统应用提供Nginx服务 "
            ),
            _c("br"),
            _vm._v(" ● CheerOcean Maas平台适配容器版Nginx Plus服务 "),
            _c("br"),
            _vm._v(" ● 提供HTTPS证书管理 "),
            _c("br"),
            _vm._v(" ● 提供CVE漏洞管理 "),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "nrbox_right" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/casePage/bz_bg2.png"),
            width: "100%",
            height: "auto",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nrbox" }, [
      _c("div", { staticClass: "nrbox_left" }, [
        _c("div", { staticClass: "lefttop_box" }, [
          _c("div", { staticClass: "nr_title" }, [_vm._v("项目背景")]),
          _c("div", { staticClass: "nr_row" }, [
            _vm._v(" 根据信创需求，需要支持信创版中间件，并且部署在其 "),
            _c("br"),
            _vm._v(" 内部的麒麟系统上面，其内部服务与服务之间的中间件 "),
            _c("br"),
            _vm._v(" 开始呈现复杂的依赖关系，系统运维的复杂度急剧增 "),
            _c("br"),
            _vm._v(" 加。 "),
          ]),
        ]),
        _c("div", { staticClass: "rightbottom_box" }, [
          _c("div", { staticClass: "nr_title" }, [_vm._v("项目目标")]),
          _c("div", { staticClass: "nr_row" }, [
            _vm._v(" ● 提供信创版中间件 "),
            _c("br"),
            _vm._v(" ● 支持CheerOcean Maas平台运行在麒麟系统上 "),
            _c("br"),
            _vm._v(" ● 支持CheerOcean Maas平台适配信创版中间件 "),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "nrbox_right" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/casePage/bz_bg3.png"),
            width: "100%",
            height: "auto",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nrbox" }, [
      _c("div", { staticClass: "nrbox_left" }, [
        _c("div", { staticClass: "lefttop_box" }, [
          _c("div", { staticClass: "nr_title" }, [_vm._v("项目背景")]),
          _c("div", { staticClass: "nr_row" }, [
            _vm._v(" 需要将原有的、传统的服务，通过容器的方式，引入 "),
            _c("br"),
            _vm._v(" DevOps流程并使用蓝绿发布，让服务运行在云平台 "),
            _c("br"),
            _vm._v(" 上。由于系统中各个应用服务部署方式、应用和运用之 "),
            _c("br"),
            _vm._v(" 间的中间件的差异，在服务容器化时，面临着较多难 "),
            _c("br"),
            _vm._v(" 题。 "),
          ]),
        ]),
        _c("div", { staticClass: "rightbottom_box" }, [
          _c("div", { staticClass: "nr_title" }, [_vm._v("项目目标")]),
          _c("div", { staticClass: "nr_row" }, [
            _vm._v(" ● 提供服务容器化服务，迁移上云 "),
            _c("br"),
            _vm._v(" ● 提供DevOps流程，对接发版，支持灰度功能 "),
            _c("br"),
            _vm._v(" ● 提供配置版本化管理，一键回滚功能 "),
            _c("br"),
            _vm._v(" ● 提供Nginx常用小工具黑白名单管理，限流功能 "),
            _c("br"),
            _vm._v(" ● 提供定制快捷功能菜单 "),
            _c("br"),
            _vm._v(" ● 提供集群/节点资产管理 "),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "nrbox_right" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/casePage/bz_bg.png"),
            width: "100%",
            height: "auto",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }