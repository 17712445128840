<template>
    <div>
        <div class="hander_fu">
            <div class="header-left">
                <p>经典案例</p>
                <span>推动行业客户数字化转型，提升企业核心业务生产率</span>
            </div>
        </div>
        <div class="contenr">
            <div class="tab_list">
                <div class="tab_left">
                    <div class="tab_li" @click="index = 0" :class="{ active: index == 0 }">某大型股份制银行</div>
                    <div class="tab_li" @click="index = 1" :class="{ active: index == 1 }">某大型证劵A</div>
                    <div class="tab_li" @click="index = 2" :class="{ active: index == 2 }">某大型证劵B</div>
                    <div class="tab_li" @click="index = 3" :class="{ active: index == 3 }">某信托登记机构</div>
                </div>
                <div class="tab_right">
                    <!--某大型股份制银行-->
                    <div v-show="index == 0">
                        <div class="tabtitle">成功案例-某大型股份制银行</div>
                        <div class="nrbox">
                            <div class="nrbox_left">
                                <div class="lefttop_box">
                                    <div class="nr_title">项目背景</div>
                                    <div class="nr_row">
                                        某股份制银行拥有的Nginx节点和集群较多，部署
                                        <br />
                                        复杂。
                                        <br />
                                        无法满足银行快速上线的要求，导致银行业务系统呈现
                                        <br />
                                        出开放性、用户量不可预测性、应用新功能更新频繁的
                                        <br />
                                        场景；系统弹性扩展能力不足的情况。
                                    </div>
                                </div>
                                <div class="rightbottom_box">
                                    <div class="nr_title">项目目标</div>
                                    <div class="nr_row">
                                        ● 提供Nginx自动扫描功能去识别类型并纳管
                                        <br />
                                        ● 提供平台化管理，统一入口，统一纳管
                                        <br />
                                        ● 提供版本仓库用于统一管理Nginx版本
                                        <br />
                                        ● 提供定制版可视化大屏
                                    </div>
                                </div>
                            </div>
                            <div class="nrbox_right">
                                <img src="@/assets/casePage/bz_bg1.png" width="100%" height="auto" />
                            </div>
                        </div>
                    </div>
                    <!--某大型证劵A-->
                    <div v-show="index == 1">
                        <div class="tabtitle">成功案例-某大型证劵A</div>
                        <div class="nrbox">
                            <div class="nrbox_left">
                                <div class="lefttop_box">
                                    <div class="nr_title">项目背景</div>
                                    <div class="nr_row">
                                        为了同时满足业务部门对内部系统的统一管理和维护，
                                        <br />
                                        落地建设了运维管理平台等相关系统。随着”一网四
                                        <br />
                                        云”建设的逐步深入与IDC运维规模的扩张，管理效率
                                        <br />
                                        低下，需要提高稳定性。
                                    </div>
                                </div>
                                <div class="rightbottom_box">
                                    <div class="nr_title">项目目标</div>
                                    <div class="nr_row">
                                        ● 提供将Nginx从虚机迁移至容器部署；使用容器版Nginx Plus实例，为传统应用提供Nginx服务
                                        <br />
                                        ● CheerOcean Maas平台适配容器版Nginx Plus服务
                                        <br />
                                        ● 提供HTTPS证书管理
                                        <br />
                                        ● 提供CVE漏洞管理
                                    </div>
                                </div>
                            </div>
                            <div class="nrbox_right">
                                <img src="@/assets/casePage/bz_bg2.png" width="100%" height="auto" />
                            </div>
                        </div>
                    </div>
                    <!--某大型证劵B-->
                    <div v-show="index == 2">
                        <div class="tabtitle">成功案例-某大型证劵B</div>
                        <div class="nrbox">
                            <div class="nrbox_left">
                                <div class="lefttop_box">
                                    <div class="nr_title">项目背景</div>
                                    <div class="nr_row">
                                        根据信创需求，需要支持信创版中间件，并且部署在其
                                        <br />
                                        内部的麒麟系统上面，其内部服务与服务之间的中间件
                                        <br />
                                        开始呈现复杂的依赖关系，系统运维的复杂度急剧增
                                        <br />
                                        加。
                                    </div>
                                </div>
                                <div class="rightbottom_box">
                                    <div class="nr_title">项目目标</div>
                                    <div class="nr_row">
                                        ● 提供信创版中间件
                                        <br />
                                        ● 支持CheerOcean Maas平台运行在麒麟系统上
                                        <br />
                                        ● 支持CheerOcean Maas平台适配信创版中间件
                                    </div>
                                </div>
                            </div>
                            <div class="nrbox_right">
                                <img src="@/assets/casePage/bz_bg3.png" width="100%" height="auto" />
                            </div>
                        </div>
                    </div>
                    <!--某信托登记机构-->
                    <div v-show="index == 3">
                        <div class="tabtitle">成功案例-某信托登记机构</div>
                        <div class="nrbox">
                            <div class="nrbox_left">
                                <div class="lefttop_box">
                                    <div class="nr_title">项目背景</div>
                                    <div class="nr_row">
                                        需要将原有的、传统的服务，通过容器的方式，引入
                                        <br />
                                        DevOps流程并使用蓝绿发布，让服务运行在云平台
                                        <br />
                                        上。由于系统中各个应用服务部署方式、应用和运用之
                                        <br />
                                        间的中间件的差异，在服务容器化时，面临着较多难
                                        <br />
                                        题。
                                    </div>
                                </div>
                                <div class="rightbottom_box">
                                    <div class="nr_title">项目目标</div>
                                    <div class="nr_row">
                                        ● 提供服务容器化服务，迁移上云
                                        <br />
                                        ● 提供DevOps流程，对接发版，支持灰度功能
                                        <br />
                                        ● 提供配置版本化管理，一键回滚功能
                                        <br />
                                        ● 提供Nginx常用小工具黑白名单管理，限流功能
                                        <br />
                                        ● 提供定制快捷功能菜单
                                        <br />
                                        ● 提供集群/节点资产管理
                                    </div>
                                </div>
                            </div>
                            <div class="nrbox_right">
                                <img src="@/assets/casePage/bz_bg.png" width="100%" height="auto" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'casePage',
    data() {
        return {
            index: '0',
            mode: 'left'
        }
    },
    watch: {
        //监听路由变化显示不同内容
        $route: {
            handler() {
                console.log('获取', this.$route.query.index)
                this.index = this.$route.query.index
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {}
}
</script>
<style scoped>
/**头部样式 */
.header-left {
    float: left;
    width: 500px;
    margin-top: 100px;
    margin-left: 12%;
}
.header-left p {
    font-size: 30px;
    font-weight: bold;
}
.header-left span {
    font-size: 18px;
    line-height: 32px;
}
.hander_fu {
    width: 100%;
    height: 24rem;
    background-image: url(../../assets/casePage/bananer.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 40px;
}
.contenr {
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
}
/**左边菜单 */
.tab_list {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: flex-start;
}
.tab_left {
    width: 180px;
    border-right: 1px solid #cac9c9;
}
/**右边部分 */
.tab_right {
    flex: 1;
}
.tab_li {
    padding: 20px 0;
    font-size: 18px;
    cursor: pointer;
}
.active {
    color: #409eff;
}
.tabtitle {
    color: #fff;
    background: #409eff;
    font-size: 24px;
    padding: 16px 0;
    text-indent: 20px;
}
.nrbox {
    margin: 20px 0 20px 20px;
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-between;
}
.nrbox_left {
    flex: 1;
    margin-right: 10px;
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction: column;
}
.nrbox_right {
    flex: 1;
    margin-left: 10px;
    background: #fff;
    box-shadow: 0px 3px 22px 0px rgba(213, 213, 213, 0.5);
    padding: 40px;
}
.lefttop_box {
    background: #fff;
    box-shadow: 0px 3px 22px 0px rgba(213, 213, 213, 0.5);
    padding: 40px;
    flex: 1;
    margin-bottom: 10px;
}
.rightbottom_box {
    background: #fff;
    box-shadow: 0px 3px 22px 0px rgba(213, 213, 213, 0.5);
    padding: 40px;
    flex: 1;
    margin-top: 10px;
}
.nr_title {
    border-left: 4px solid #409eff;
    text-indent: 10px;
    font-size: 22px;
    font-weight: 500;
    color: #333333;
    text-align: justify;
}
.nr_row {
    text-align: justify;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #333333;
    margin-top: 20px;
    line-height: 180%;
}
</style>
